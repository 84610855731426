@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #181920;
}

.svg-shadow {
  -webkit-filter: drop-shadow(
    3px 3px 2px rgba(0, 0, 0, 0.3)
  );
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.3));
}

/* width */
::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #282c34;
  border-left: 1px solid #4d535f;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #323741;
  border-left: 1px solid #4d535f;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #414855;
}

.step-check-path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}
.step-check-svg {
  stroke-dashoffset: 1000;
  animation: dash-check 0.7s 0.15s ease-in-out forwards;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator,
input[type='time']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: 1700;
  }
  100% {
    stroke-dashoffset: 2200;
  }
}
